import CoolFormInput from "./CoolFormInput";

function capitalizeFirstLetterOnly(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// This is not a username.
// Use this for a first name or last name.
export default function CoolFormInputName({
  name,
  placeholder,
  hasInitialValue = false,
  required = false,
}) {
  return (
    <CoolFormInput
      name={name}
      placeholder={placeholder}
      hasInitialValue={hasInitialValue}
      rules={[
        {
          required: required,
          message: `Please enter your ${placeholder.toLowerCase()}.`,
        },
        { min: 1, message: "Name must be at least 1 characters." },
        { max: 80, message: "Name must be at most 80 characters." },
        {
          // allow any letter, space, hyphen, or apostrophe only
          pattern: /^[a-zA-Z\s-']+$/,
          message:
            capitalizeFirstLetterOnly(placeholder) +
            " can only include letters, hyphen, apostrophe, and spaces.",
        },
      ]}
    />
  );
}

/* Probably no family icon, so commenting this out.
import { ReactComponent as singleIcon } from "../../assets/img/CreditWheel/credit-single-icon.svg";
import { ReactComponent as single1KidIcon } from "../../assets/img/CreditWheel/credit-single-1kid-icon.svg";
import { ReactComponent as single2KidIcon } from "../../assets/img/CreditWheel/credit-single-2kid-icon.svg";
import { ReactComponent as single3KidIcon } from "../../assets/img/CreditWheel/credit-single-3kid-icon.svg";
import { ReactComponent as single4KidIcon } from "../../assets/img/CreditWheel/credit-single-+kid-icon.svg";

import { ReactComponent as coupleIcon } from "../../assets/img/CreditWheel/credit-couple-icon.svg";
import { ReactComponent as couple1KidIcon } from "../../assets/img/CreditWheel/credit-couple-1kid-icon.svg";
import { ReactComponent as couple2KidIcon } from "../../assets/img/CreditWheel/credit-couple-2kid-icon.svg";
import { ReactComponent as couple3KidIcon } from "../../assets/img/CreditWheel/credit-couple-3kid-icon.svg";
import { ReactComponent as couple4KidIcon } from "../../assets/img/CreditWheel/credit-couple-+kid-icon.svg";
*/

import { ReactComponent as carIcon } from "../../assets/img/CreditWheel/credit-car-icon.svg";
import { ReactComponent as cardIcon } from "../../assets/img/CreditWheel/credit-card-icon.svg";
import { ReactComponent as healthIcon } from "../../assets/img/CreditWheel/credit-health-icon.svg";
import { ReactComponent as homeIcon } from "../../assets/img/CreditWheel/credit-home-icon.svg";
import { ReactComponent as otherIcon } from "../../assets/img/CreditWheel/credit-other-icon.svg";
import { ReactComponent as paydayIcon } from "../../assets/img/CreditWheel/credit-payday-icon.svg";
import { ReactComponent as personalIcon } from "../../assets/img/CreditWheel/credit-personal-icon.svg";
import { ReactComponent as studentIcon } from "../../assets/img/CreditWheel/credit-student-icon.svg";

/*
function getFamilyIcon(config) {
  const familyIcons = [
    [
      singleIcon,
      single1KidIcon,
      single2KidIcon,
      single3KidIcon,
      single4KidIcon,
    ],
    [
      coupleIcon,
      couple1KidIcon,
      couple2KidIcon,
      couple3KidIcon,
      couple4KidIcon,
    ],
  ];
  const numKids = config.family.kidAgeRanges.length;
  const familyIcon = familyIcons[+config.family.married][numKids];
  return familyIcon;
}
*/

export default function getCategoryIcons(config) {
  const categoryIcons = {
    N_Icon: personalIcon,
    NE_Icon: homeIcon,
    E_Icon: carIcon,
    SE_Icon: studentIcon,
    S_Icon: otherIcon,
    SW_Icon: healthIcon,
    W_Icon: paydayIcon,
    NW_Icon: cardIcon,
  };
  return categoryIcons;
}

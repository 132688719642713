import {
  SimpleFormFooter,
  SingleButtonFooter,
} from "../../components/UI/FormFooter";
import * as S from "./Forms/styledFormComponents";
import styled from "styled-components";
import { useEffect } from "react";
import { ReactComponent as Icon } from "../../assets/img/Logo/moneyflow-icon.svg";

export const InfoDisplay = ({ category, onFinish, previousPage }) => {
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = "hidden";

    // Enable scrolling again when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <Wrapper>
      <MainContainer>
        <div></div>
        <Icon height={"20%"} />
        <Text3>Let's personalize your MoneyFlow experience!</Text3>
        <Text2>
          A few simple questions will help us personalize MoneyFlow just for
          you.
        </Text2>
        <div></div>
        <div></div>
        <Text2 weight={800}>Ready to get started?</Text2>
      </MainContainer>
      <SecondRing />
      <GradientSection />

      <SingleButtonFooter
        onPrevButton={null}
        onNextButton={(e) => onFinish(e)}
        disabledPrevButton={true}
        nextBtnText={"Let's Go!"}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // overflow: hidden;
  height: 100vh;
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: 100%;
  max-width: 430px;
`;
const MainContainer = styled.div`
  background-color: white;
  width: 100vw;
  height: 60vh;
  border-radius: 0 0 60% 60%/ 0 0 30% 30%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

const SecondRing = styled.div`
  background-color: #d9f7bd;
  width: 100vw;
  height: 68vh;
  border-radius: 0 0 60% 60%/ 0 0 25% 25%;
  position: absolute;
  z-index: 5;
`;

const GradientSection = styled.div`
  width: 100vw;
  height: 60vh;
  background: linear-gradient(to bottom, #69cd74 0%, #d9f7bd 100%);
  position: absolute;
  top: 40vh;
  z-index: 0;
`;

const Text2 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 1.25rem; /* 20/16 */
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
  width: 90%;
`;

const Text3 = styled.div`
  font-family: AccentFont;
  font-size: ${(props) => (props.size ? props.size : 1.75)}rem; /* 20/16 */
  font-weight: 800;
  line-height: 2.3rem; /* 27/16 */
  text-align: center;
  width: 90%;
`;

export const monthmap = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthABV = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthNameLookUp = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

// IN: month: 1..12  Note the 1-based index!
// returns the number of days there are in month/year (eg 2/2024 returns 29)
export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

// returns number of days left in current month
export function daysLeftInMonth() {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const daysLeft = lastDay.getDate() - now.getDate();
  return daysLeft + 1;
}

export function daysLeftInMonthString() {
  const daysLeft = daysLeftInMonth();
  if (daysLeft === 1) {
    return "1 day left";
  } else {
    return daysLeft + " days left";
  }
}

// IN: prevDate: Date() format: a date earlier than or equal to today
//
// returns number of days between prevDate and today
export function daysSince(prevDate) {
  prevDate.setHours(0, 0, 0, 0); // clear time part

  const today = new Date();
  today.setHours(0, 0, 0, 0); // clear time part

  const timeDifference = today - prevDate; // in milliseconds

  // convert from milliseconds to days
  const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
  console.log(`It's been ${daysDifference} days since the account was created`);

  return daysDifference;
}

// IN: month: 1..12
// IN: year: 4 digit year
export function isCurrentMonth(month, year) {
  const today = new Date();
  return month === today.getMonth() + 1 && year === today.getFullYear();
}

// returns { lastMonth: lastMonth, lastYear: lastYear } which corresponds to the previous month from today
// lastMonth: 1..12
export function getPreviousMonth() {
  const now = new Date();
  let lastMonth = now.getMonth(); // current month 0..11
  let lastYear = now.getFullYear();

  if (lastMonth === 0) {
    // handle current month in Jan
    lastMonth = 11;
    lastYear -= 1;
  } else {
    lastMonth -= 1;
  }

  return { lastMonth: lastMonth + 1, lastYear }; // +1 to convert to 1..12
}

// IN: timestamp, such as "2024-10-11T00:28:03.962130"
// RETURN: formatted date and time, like this "10/11/2024 12:28 AM"
export function prettyPrintDateTime(isoTimestamp) {
  const d = new Date(isoTimestamp);

  const date = d.toLocaleDateString();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  return `${date} ${hours % 12}:${minutes} ${ampm}`;
}

const pad = (num) => (num < 10 ? "0" : "") + num;

// returns now, in YYYY-MM-DD HH:MM:SS format
export function getFormattedTimestamp() {
  const date = new Date();
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// returns now, in YYYY-MM-DDTHH:MM:SS.MMMZ format
export function getTimestamp() {
  const date = new Date();
  return date.toISOString();
}

// Convert date format.  Examples:
// from '7/21' to 'Jul 21'
// from '12/3' to 'Dec 3'
export function formatAsMonthNameDD(dateString) {
  const [month, day] = dateString.split("/");
  const monthIndex = parseInt(month) - 1;
  const dayInt = parseInt(day);

  // Validate input
  if (monthIndex < 0 || monthIndex > 11) {
    throw new Error(
      `formatAsMonthNameDD(): Invalid month number: ${monthIndex}`,
    );
  }
  if (dayInt < 0 || dayInt > 31) {
    throw new Error(`formatAsMonthNameDD(): Invalid day number: ${dayInt}`);
  }

  // monthABV is ["Jan", "Feb", etc]
  return `${monthABV[monthIndex]} ${day}`;
}

// IN: startMonth is 1..12
export function calculateEndDate(startYear, startMonth, numMonths) {
  const monthIndex = startMonth - 1; // 0..11
  const totalMonths = monthIndex + numMonths;
  const endYear = startYear + Math.floor(totalMonths / 12);
  const endMonth = (totalMonths % 12) + 1;
  return { endYear, endMonth };
}

// IN: startMonth is 1..12
export function getNumMonthsQueryString(startMonth, startYear, numMonths) {
  console.log(
    `getNumMonthsQueryString(): startMonth=${startMonth} startYear=${startYear} numMonths=${numMonths}`,
  );
  console.assert(
    startMonth >= 1 && startMonth <= 12,
    "getNumMonthsQueryString(): startMonth must be between 1 and 12.",
  );
  console.assert(
    startYear >= 1900 && startYear <= 2200,
    "getNumMonthsQueryString(): startMonth must be between 1900 and 2200.",
  );
  console.assert(
    numMonths >= 1 && numMonths <= 120,
    "getNumMonthsQueryString(): numMonths must be between 1 and 120.",
  );

  const start =
    "" + startYear + "-" + startMonth.toString().padStart(2, "0") + "-01";
  // handle multi-year
  const { endYear, endMonth } = calculateEndDate(
    Number(startYear),
    Number(startMonth),
    numMonths,
  );

  console.assert(
    endMonth >= 1 && endMonth <= 12,
    "getNumMonthsQueryString(): endMonth must be between 1 and 12.",
  );
  console.assert(
    endYear >= 1900 && endYear <= 2200,
    "getNumMonthsQueryString(): endYear must be between 1900 and 2200.",
  );
  const end = "" + endYear + "-" + endMonth.toString().padStart(2, "0") + "-01";
  console.log("getNumMonthsQueryString()");
  console.log("_gte_" + start + "__and___lt_" + end);
  return "_gte_" + start + "__and___lt_" + end;
}

// IN: startMonth is 1..12
export function get1MonthQueryString(startMonth, startYear) {
  return getNumMonthsQueryString(startMonth, startYear, 1);
}

export function getCurrentMonthQueryString() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  return get1MonthQueryString(currentMonth, currentYear);
}

// current month counts as 1 month.
// Eg. if numMonths is 3, then it's for this month and the 2 previous months
export function getLastNumMonthsQueryString(numMonths) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  let startMonth = currentMonth - numMonths + 1;
  let startYear = currentYear;
  while (startMonth <= 0) {
    startMonth += 12;
    startYear -= 1;
  }
  // startMonth is 1..12
  return getNumMonthsQueryString(startMonth, startYear, numMonths);
}

import CoolFormInput from "./CoolFormInput";

export default function CoolFormInputEmail({
  placeholder,
  hasInitialValue = false,
  required = false,
}) {
  return (
    <CoolFormInput
      name="email_address"
      placeholder={placeholder}
      hasInitialValue={hasInitialValue}
      rules={[
        {
          required: required,
          message: "Please enter a valid email address.",
        },
        { min: 5, message: "Email must be at least 5 characters." },
        { max: 80, message: "Email must be at most 80 characters." },
        {
          validator: (_, value) => {
            // require @ and then .
            const emailRegex =
              /^[-~+._0-9a-zA-Z]+@[-~+._0-9a-zA-Z]+\.[-~+._0-9a-zA-Z]+$/;
            if (!/^[0-9]+/.test(value) && emailRegex.test(value)) {
              return Promise.resolve();
            } else if (!/@/.test(value)) {
              return Promise.reject("Email is missing @ symbol.");
            } else if (/^\d/.test(value)) {
              // begins with a number
              return Promise.reject("Email can not start with a number.");
            } else {
              return Promise.reject(
                "Letters, numbers, +, -, ~, dot (.) or underscore (_) only for emails.",
              );
            }
          },
        },
      ]}
    />
  );
}

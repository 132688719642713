// constants for GoalsWheel's layout of icons and text in the wheel.

// all angles are symmetric across the y-axis
// e.g. NW is the mirror reflection of NE
// This is important since the GoalsWheels may have
// 1000s of different icons and labels,
// so it can't be custom tweaked like MoneyWheel
// (with its set icons and labels) is.
export const Text_Below_Position_Angle_Map = {
  NW: 0.77,
  N: 0.5,
  NE: 0.23,
  E: -0.02,
  SE: -0.265,
  S: -0.5,
  SW: -0.735,
  W: 1.02,
};

// all angles are symmetric across the y-axis
// e.g. NW is the mirror reflection of NE
export const Text_Above_Position_Angle_Map = {
  NW: 0.75,
  N: 0.5,
  NE: 0.25,
  E: -0.02,
  SE: -0.27,
  S: -0.5,
  SW: -0.73,
  W: 1.02,
};

// all radii are symmetric across the y-axis
// e.g. NW is the mirror reflection of NE
export const Radius_Map = {
  NW: 126,
  N: 120,
  NE: 126,
  E: 130,
  SE: 137,
  S: 140,
  SW: 137,
  W: 130,
};

export const Size_Map = {
  NW: 40,
  N: 40,
  NE: 40,
  E: 40,
  SE: 40,
  S: 40,
  SW: 40,
  W: 40,
};


class BackendUrl {
  url = "";

  constructor() {
    this.url = "https://" + window.location.hostname + ":5555";
    console.log("backend url is " + this.url);
  }

  getBackendUrl = () => {
    return this.url;
  };
}

const backend_url = new BackendUrl();
export default backend_url;


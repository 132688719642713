import { useEffect, useState } from "react";
import { useUser } from "../../../state/UserProvider";
import { BasicFamilyIcon } from "./BasicFamilyIcon";
import FormFooter from "../../../components/UI/FormFooter";
import "./style.css";
import * as S from "./styledFormComponents";
import styled from "styled-components";
import { Spacer, IconPlacer } from "./IconSpacerUtils.js";
import { CloseSquareOutlined } from "@ant-design/icons";

export const YearOfDependentForm = ({
  onFinish,
  onFinishFailed,
  previousPage,
  numDependents,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { currentUser } = useUser();

  const initialAges = [...currentUser.dependentAges];
  // pad initialAges array with the current year,
  // so there's an element for each dependent.
  while (initialAges.length < numDependents) {
    initialAges.push(0);
  }
  // if the user has decreased numDependents from what was previously
  // saved, shorten initialAges
  initialAges.splice(numDependents);
  console.assert(
    initialAges.length === numDependents,
    `initialAges.length should be ${numDependents}`,
  );
  const [ages, setAges] = useState(initialAges);

  let numberPhrase = currentIndex === 0 ? "first" : "next";

  return (
    <div className={"form-wrapper"}>
      <Spacer />
      <IconPlacer>
        <BasicFamilyIcon
          maritalStatus={currentUser.marital_status}
          dependents={numDependents}
        />
      </IconPlacer>
      <S.FormQuestion>
        What year was your <br /> {numberPhrase} dependent born?
      </S.FormQuestion>
      <S.FormQuestionHelp>
        Knowing the ages of your dependents helps us better understand and cater
        to your family's unique needs.
      </S.FormQuestionHelp>
      <AgeDisplay
        ages={ages}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <AgeInput
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        ages={ages}
        setAges={setAges}
      />
      <FormFooter
        onPrevButton={(e) => previousPage(e)}
        disabledNextButton={true}
        onNextButton={
          ages[ages.length - 1] !== 0
            ? () => {
                //should add better validation to make sure that all the ages/birthears are valid
                onFinish({ ages: ages });
              }
            : "disabled"
        }
      />
    </div>
  );
};

export const AgeInput = ({ currentIndex, setCurrentIndex, ages, setAges }) => {
  const editAge = (val) => {
    if (val === "DEL") {
      let newAge = Math.floor(ages[currentIndex] / 10);
      const temp = [...ages];
      temp[currentIndex] = newAge;
      setAges(temp);
    } else if (val === "ENTER") {
      setCurrentIndex((currentIndex + 1) % ages.length);
    } else {
      let newAge = ages[currentIndex] * 10 + val;
      const temp = [...ages];
      temp[currentIndex] = newAge;
      setAges(temp);
    }
  };
  return (
    <div>
      <KeyboardContainer>
        <KeyboardButton
          onClick={() => {
            editAge(1);
          }}
          data-cy="1"
        >
          {" "}
          1{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(2);
          }}
          data-cy="2"
        >
          {" "}
          2{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(3);
          }}
          data-cy="3"
        >
          {" "}
          3{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(4);
          }}
          data-cy="4"
        >
          {" "}
          4{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(5);
          }}
          data-cy="5"
        >
          {" "}
          5{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(6);
          }}
          data-cy="6"
        >
          {" "}
          6{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(7);
          }}
          data-cy="7"
        >
          {" "}
          7{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(8);
          }}
          data-cy="8"
        >
          {" "}
          8{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(9);
          }}
          data-cy="9"
        >
          {" "}
          9{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge("DEL");
          }}
          data-cy="del"
          backgroundColor="#f2f2f2"
        >
          <CloseSquareOutlined />
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge(0);
          }}
          data-cy="0"
        >
          {" "}
          0{" "}
        </KeyboardButton>
        <KeyboardButton
          onClick={() => {
            editAge("ENTER");
          }}
          data-cy="enter"
          backgroundColor="#d9f7bd"
        >
          {" "}
          Enter{" "}
        </KeyboardButton>
      </KeyboardContainer>
      <KeyboardContainerExtender />
    </div>
  );
};

export const AgeDisplay = ({ ages, currentIndex, setCurrentIndex }) => {
  const offsetSize = 80; //80 is the width plus the gape between the boxes (70 + 10)

  //we subtract 35 because it is half the width of a box
  let offset = -35 - offsetSize * currentIndex;

  return (
    <OuterAgeHolder>
      <AgeHolder offset={offset}>
        {" "}
        {ages &&
          ages.map((age, index) => {
            let ageIndex = "ageBox-" + index;

            return (
              <AgeBox
                data-cy={ageIndex}
                key={index}
                selected={index === currentIndex}
                onClick={() => {
                  console.log("clicked on: ", index);
                  setCurrentIndex(index);
                }}
              >
                {age === 0 ? "" : age}
              </AgeBox>
            );
          })}
      </AgeHolder>
    </OuterAgeHolder>
  );
};

const OuterAgeHolder = styled.div`
  position: relative;
  margin-bottom: 10px;
  top: -32px;
`;
const AgeHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: fixed;
  left: calc(50% + ${(props) => props.offset}px);
  transition: left 0.5s;
`;

const AgeBox = styled.div`
  width: 70px;
  min-width: 70px;
  height: 3em;
  background: red;
  border-radius: 10px;
  text-align: center;
  font-size: 1.5em;
  background: ${(props) => (props.selected ? "#fafafa" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#000000" : "#b3b3b3")};
  border: 1px solid ${(props) => (props.selected ? "#68cd74" : "#b3b3b3")};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KeyboardContainer = styled.div`
  width: 100%;
  aspect-ratio: 6 / 4;
  background: #eef7ee;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  // gap: 3%;
  justify-content: space-between;
`;
const KeyboardContainerExtender = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  background: #eef7ee;
`;
const KeyboardButton = styled.button`
  width: 30%;
  height: 22%;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fafafa"};
  margin: 4px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 5px 0px rgba(212, 212, 212, 0.6);
  cursor: pointer;
  font-size: 1.5rem;
  color: #666666;
`;

const EnterAgeText = styled.h4`
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-weight: 700; /* Figma says 500 */
  font-size: 1.25rem; /* 20/16 */
  line-height: 2.25em; /* 36/16 */
  text-align: left;
  width: 100%;
  color: var(--grey-dark);
`;

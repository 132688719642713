import CoolFormInput from "./CoolFormInput";

export default function CoolFormInputUsernameEmailOrPhone({
  name,
  placeholder,
  hasInitialValue = false,
  required = false,
}) {
  return (
    <CoolFormInput
      name={name}
      placeholder={placeholder}
      hasInitialValue={hasInitialValue}
      rules={[
        {
          required: required,
          message: "Please enter your username or email",
          // TODO: later add "or phone number", once we are verifying them with txt codes.
        },
        // since login "username" can really be username, email, or
        // phone number, min and max are the union of the min and max
        // for username, email, and phone number.
        { min: 5, message: "Username must be at least 5 characters." },
        { max: 80, message: "Username must be at most 80 characters." },
        {
          validator: (_, value) => {
            // match username, email or phone number
            // For an email, require @ and then .
            const emailRegex =
              /^[-~+._0-9a-zA-Z]+@[-~+._0-9a-zA-Z]+\.[-~+._0-9a-zA-Z]+$/;
            if (
              /^[a-z][a-z0-9_]+$/.test(value) ||
              (!/^[0-9]+/.test(value) && emailRegex.test(value)) ||
              /^[0-9+\-() ]+$/.test(value)
            ) {
              // it's a valid username, email or phone number
              return Promise.resolve();
            }

            // The rest of this function is guessing what the user
            // was trying to input into the field, and then reporting
            // what mistake they probably made.
            if (/@/.test(value) || /\.(com|net|org|edu)$/.test(value)) {
              // probably email, since it contains an `@`
              // or ends with a common top-level domain, like .com
              if (!/@/.test(value)) {
                return Promise.reject("Email is missing @ symbol.");
              } else if (/^\d/.test(value)) {
                // begins with a number
                return Promise.reject("Email can not start with a number.");
              } else {
                return Promise.reject(
                  "Letters, numbers, +, -, ~, dot (.) or underscore (_) only for emails.",
                );
              }
            }
            if (/\d{5,}/.test(value)) {
              // probably a telephone number, since it contains at least 5 digits
              return Promise.reject(
                "Phone number can only include numbers and the symbols + - ( ) and spaces.",
              );
            }

            // probably a username
            if (/^[^a-z]/.test(value)) {
              // doesn't begin with a lowercase letter
              return Promise.reject(
                "Username must start with a lowercase letter.",
              );
            }
            return Promise.reject(
              //"Doesn't match username, email, or phone number",
              "Username can only include lowercase letters, numbers, and " +
                "underscores.",
            );
          },
        },
      ]}
    />
  );
}

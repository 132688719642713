import styled from "styled-components";
import { ReactComponent as MinusCircle } from "../../../assets/img/Other/MinusCircle.svg";
import { ReactComponent as IncomeIcon } from "../../../assets/img/Other/income-icon.svg";
export default function PlanContent({
  budgetInfo,
  changePlanAmount,
  category,
}) {
  console.log("budgetInfo", budgetInfo);
  return (
    <MainContainer>
      <MainCard>
        <p>
          {" "}
          <span className="big">${budgetInfo.budgetAmount} </span> for
          Entertainment of ${budgetInfo.totalBudget} total plan
        </p>
      </MainCard>
      <SmallCardContainer>
        <SmallCard>
          <MinusCircle height="45px" />
          <SmallCardText>
            <p className="small">Spent</p>
            <p className="big">-${budgetInfo.actualSpend}</p>
          </SmallCardText>
        </SmallCard>
        <SmallCard>
          <IncomeIcon height="30px" />
          <SmallCardText>
            <p className="small">Remaining</p>
            <p className="big">${budgetInfo.remaining}</p>
          </SmallCardText>
        </SmallCard>
      </SmallCardContainer>
      <EditButton>Edit</EditButton>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 73px; //bottom navbar height
  & > * {
    flex: 0;
  }
`;

const MainCard = styled.div`
  border-radius: 20px;
  width: 90%;
  padding: 20px;
  box-shadow: 0px 2px 10px 0px #0b0d1626;

  .big {
    font-size: 38px;
    font-weight: 400;
  }
`;
const SmallCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;

const SmallCard = styled.div`
  border-radius: 40px;
  width: 44%;
  padding: 6px 25px 6px 25px;
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SmallCardText = styled.div`
  font-weight: 500;
  p {
    margin: 0;
  }
  .small {
    font-size: 10px;
  }
  .big {
    font-size: 20px;
  }
`;

const EditButton = styled.button`
  border-radius: 40px;
  background: linear-gradient(180deg, #ff773d 0%, #e64e2f 174.51%);
  box-shadow: 0px 2px 8px 0px #0b0d161a;
  width: 90%;
  border: none;
  min-height: 51px;
  margin-top: auto;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  p {
    margin: auto;
  }
`;

import styled from "styled-components";
import { SearchOutlined, UpOutlined } from "@ant-design/icons";

export default function TableHeader(props) {
  return (
    <MainContainer>
      <div>
        <Search
          placeholder="Search"
          onChange={(e) => {
            props.searchFunction(e.target.value);
          }}
        ></Search>
        <SearchOutlined style={{ position: "relative", right: "25px" }} />
      </div>
      <SortBy>
        Sort By <SortIcon />
      </SortBy>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const Search = styled.input`
  width: 204px;
  height: 36px;
  box-shadow: 0px 2px 8px 0px #d4d4d4cc;
  border-radius: 100px;
  border: none;
  padding-left: 15px;
  padding-right: 28px;
  color: #666666;
  font-size: 16px;

  &:focus {
    outline: solid #25b764;
  }
`;

const SortBy = styled.div`
  width: 111px;
  height: 36px;
  box-shadow: 0px 2px 8px 0px #d4d4d4cc;
  background: #ffffff;
  border-radius: 100px;
  color: #666666;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-size: 16px;
`;

const SortIcon = styled(UpOutlined)`
  transform: rotate(180deg);
`;

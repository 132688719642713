import axios from "axios";
import { getMoneyFlowToken } from "../state/stateApplication";
import BackendUrl from "./backend_url";
import { isNull } from "./utils";
import { TokenTimeout } from "./tokenTimeout";

class Backend {
  backend_url = "";

  constructor() {
    this.backend_url = BackendUrl.getBackendUrl();
    console.log("backend url is " + this.backend_url);
    this.tokenTimeout = new TokenTimeout();
  }

  getBackendUrl = () => {
    return this.backend_url;
  };

  getResponseData = (response) => {
    this.tokenTimeout.resetTimeout();
    let responseData;

    if (isNull(response.data)) {
      responseData = {};
    } else {
      responseData = response.data;
    }

    if (isNull(response.status)) {
      responseData.http_status_code = 500; // server error
    } else {
      responseData.http_status_code = response.status;
    }

    if (isNull(responseData.message)) {
      if (isNull(response.statusText)) {
        responseData.message = "Server Error";
      } else {
        responseData.message = response.statusText;
      }
    }

    if (isNull(responseData.success)) {
      responseData.success = false;
    }

    return responseData;
  };

  getErrorResponseData = (error) => {
    this.tokenTimeout.resetTimeout();
    let responseData;

    console.log("Backend error");

    if (!isNull(error.response)) {
      if (isNull(error.response.data)) {
        responseData = {};
      } else {
        responseData = error.response.data;
      }

      if (isNull(error.response.status)) {
        responseData.http_status_code = 500;
      } else {
        responseData.http_status_code = error.response.status;
      }

      if (isNull(responseData.message)) {
        if (isNull(error.message)) {
          responseData.message = "Server Error";
        } else {
          responseData.message = error.message;
        }
      }
    } else if (!isNull(error.request)) {
      responseData = {};

      if (isNull(error.request.status)) {
        responseData.http_status_code = 400;
      } else {
        responseData.http_status_code = error.request.status;
      }

      if (isNull(error.message)) {
        responseData.message = "Request Error";
      } else {
        responseData.message = error.message;
      }
    } else {
      responseData = {};
      responseData.http_status_code = 400;

      if (isNull(error.message)) {
        responseData.message = "Request Error";
      } else {
        responseData.message = error.message;
      }
    }

    if (isNull(responseData.success)) {
      responseData.success = false;
    }

    return responseData;
  };

  get = async (endpoint_url) => {
    let response;
    let responseData;

    // XXX TODO Remove
    console.log("backend get " + endpoint_url);

    try {
      response = await axios.get(this.backend_url + endpoint_url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": getMoneyFlowToken(),
        },
        httpsAgent: this.agent,
      });

      responseData = this.getResponseData(response);
    } catch (error) {
      responseData = this.getErrorResponseData(error);
    }

    if (!responseData.success) {
      console.log("Backend.get failed:", responseData.message);
    }

    // XXX TODO Remove
    //console.log("Response data: " + JSON.stringify(responseData));

    return responseData;
  };

  post = async (endpoint_url, data) => {
    let response;
    let responseData;

    // XXX TODO Remove
    console.log("backend post " + endpoint_url + " data: ");
    console.log(data);

    try {
      response = await axios.post(this.backend_url + endpoint_url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": getMoneyFlowToken(),
        },
        httpsAgent: this.agent,
      });

      responseData = this.getResponseData(response);
    } catch (error) {
      responseData = this.getErrorResponseData(error);
    }

    if (!responseData.success) {
      console.log("Backend.post failed:", responseData.message);
    }

    // XXX TODO Remove
    console.log("Response data: " + JSON.stringify(responseData));

    return responseData;
  };

  patch = async (endpoint_url, data) => {
    let response;
    let responseData;

    // XXX TODO Remove
    console.log("backend patch " + endpoint_url + " data: ");
    console.log(data);

    try {
      response = await axios.patch(this.backend_url + endpoint_url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": getMoneyFlowToken(),
        },
        httpsAgent: this.agent,
      });

      responseData = this.getResponseData(response);
    } catch (error) {
      responseData = this.getErrorResponseData(error);
    }

    if (!responseData.success) {
      console.log("Backend.patch failed:", responseData.message);
    }

    // XXX TODO Remove
    console.log("Response data: " + JSON.stringify(responseData));

    return responseData;
  };

  delete = async (endpoint_url) => {
    let response;
    let responseData;

    // XXX TODO Remove
    console.log("backend delete " + endpoint_url);

    try {
      response = await axios.delete(this.backend_url + endpoint_url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": getMoneyFlowToken(),
        },
        httpsAgent: this.agent,
      });

      responseData = this.getResponseData(response);
    } catch (error) {
      responseData = this.getErrorResponseData(error);
    }

    if (!responseData.success) {
      console.log("Backend.delete failed:", responseData.message);
    }

    // XXX TODO Remove
    console.log("Response data: " + JSON.stringify(responseData));

    return responseData;
  };
}

const backend = new Backend();
export default backend;

import { MoneyFlowHeaderNoMenu } from "../../components/PageComponents/MoneyFlowHeader";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import LoginContent from "./LoginContent";
import "../../styles/IPadLook.css";

function Login(props) {
  var viewportWidth = window.innerWidth;
  var viewportHeight = window.innerHeight;
  console.log(`viewport = ${viewportWidth} x ${viewportHeight}`);

  return (
    <div className="screen">
      <MoneyFlowHeaderNoMenu />
      <LoginContent />
    </div>
  );
}

export default Login;

import { MoneyFlowHeaderNoMenu } from "../../components/PageComponents/MoneyFlowHeader";
import RegisterContent from "./RegisterContent";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import "../../styles/IPadLook.css";
import "./RegisterScreen.css";

function Register(props) {
  return (
    <div className="screen">
      <MoneyFlowHeaderNoMenu />
      <RegisterContent />
    </div>
  );
}

export default Register;

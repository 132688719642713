import { MoneyFlowHeaderNoMenu } from "../../components/PageComponents/MoneyFlowHeader";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import "../../styles/IPadLook.css";

import ForgotContent from "./ForgotContent";

const PasswordForgot = () => {
  return (
    <div className="screen">
      <MoneyFlowHeaderNoMenu />
      <ForgotContent />
    </div>
  );
};

export default PasswordForgot;

import CoolFormInput from "./CoolFormInput";

export default function CoolFormInputPassword({
  name = "password",
  placeholder,
  hasInitialValue = false,
  required = false,
}) {
  return (
    <CoolFormInput
      name={name}
      placeholder={placeholder}
      hasInitialValue={hasInitialValue}
      rules={[
        { required: required, message: "Please input your password." },
        { min: 8, message: "Password must be at least 8 characters." },
        { max: 80, message: "Password must be at most 80 characters." },
        {
          // disallows:
          // To simplify the initial coding and to avoid potential
          // HTML injection attacks or escaping issues,
          // disallow these special characters: < > ' " \ /
          // To avoid user confusion, disallow all spaces before, after
          // or in the middle of passwords.
          //
          // Note: in the pattern below, - [ ]  are escaped with \
          pattern: /^[a-zA-Z0-9~!@#$%^&*()\-_=+\[\]{}|;:?.,]+$/,
          message:
            "Password can include letters, numbers, and most symbols, but not < > ' \" \\ / or spaces.",
        },
      ]}
      password={true}
    />
  );
}

export default function PlacedSVG(props) {
  // props are radius, angle, SVG, label, id, fontSize, fill, position
  // textBelowPositionAngle, textAbovePositionAngle,
  // groupRadius (the radius for the icon + text group)
  // and iconSize

  const SVG = props.svg;
  //round the label to 2 decimal places
  const label = Math.round(props.label);

  //this calculation is based off of the viewport of the main SVg in the tracker file
  //it has width and height 390 and starts at 38,38
  const xCenter = 390 / 2 + 38;
  const yCenter = 390 / 2 + 38;

  const positionAngle = props.categoryLabel
    ? props.textBelowPositionAngle
    : props.textAbovePositionAngle;
  const angle = positionAngle * Math.PI;

  let size = props.iconSize;
  let radius = props.groupRadius;

  const padding = 4;

  // Handle multi-line labels. Shrink icons and text labels.
  let categoryLabelLines; // each category can have 1 to 2 lines of text
  let categoryLabelFontSize = "15";
  // props.categoryLabel can be a boolean, string, or an array with 1 to 2 string
  if (props.categoryLabel instanceof Array) {
    categoryLabelLines = [...props.categoryLabel];
    categoryLabelFontSize = "13";
    size -= 5;
  } else {
    categoryLabelLines = [props.categoryLabel];
  }

  const x = radius * Math.cos(angle) + xCenter;
  let y = yCenter - radius * Math.sin(angle);

  //because the text is underneath the icon in the expense review, shift the coordinates up a little
  if (props.categoryLabel) {
    y -= 25;
  }

  return (
    <g>
      <svg style={{ overflow: "visible" }}>
        {/* <rect x={x-size/2} y={y-size/2 -padding - 11.25} height={size} width={size} fill="pink" /> */}
        <g>
          <text
            x={x}
            y={y - size / 2 - padding}
            id={props.text_id}
            fontSize={"17"}
            fill={props.textFill ? props.textFill : "#596064"}
            textAnchor={"middle"}
            stroke={props.textFill ? props.textFill : "#596064"}
            strokeWidth={0}
          >
            <tspan fontSize={"12"} dy="-5">
              $
            </tspan>
            <tspan dy="5">{label}</tspan>
          </text>
        </g>

        <g id={props.idon_id}>
          <SVG
            x={x - size / 2}
            y={y - size / 2 + padding}
            height={size}
            width={size}
            fill={props.fill}
            stroke={props.fill}
          ></SVG>
        </g>
        <text
          x={x}
          y={y + size}
          id={props.text_id}
          fontSize={categoryLabelFontSize}
          fill={props.textFill ? props.textFill : "#596064"}
          textAnchor={"middle"}
          strokeWidth={0}
        >
          {categoryLabelLines[0]}
        </text>
        <text
          x={x}
          y={y + size + 15}
          id={props.text_id}
          fontSize={categoryLabelFontSize}
          fill={props.textFill ? props.textFill : "#596064"}
          textAnchor={"middle"}
          strokeWidth={0}
        >
          {categoryLabelLines[1]}
        </text>

        {/* <line x1={xCenter} y1={yCenter} x2={x} y2={y} stroke="red" /> */}
      </svg>
    </g>
  );
}

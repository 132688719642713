import { ReactComponent as BankIcon } from "../../assets/img/Other/bank-icon.svg";
import styled from "styled-components";

export const IconHelper = () => {
  return (
    <Container>
      <BankIcon style={{ maxWidth: "100px" }} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

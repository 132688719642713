import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import ProgressBar from "../../components/UI/ProgressBar";
import { SingleButtonFooter } from "../../components/UI/FormFooter";
import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import styled from "styled-components";

import { ReactComponent as CelebrateIcon } from "../../assets/img/Other/celebrate-icon.svg";

export default function ExpenseReviewInfoDisplay() {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();

  useEffect(() => {
    console.log("ExpenseReview: InfoDisplay");
    // Initial Expense Review
    updateSetupStep1(
      wheelSettings.SETUP_STEP_EXPENSE_REVIEW_INFO,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
  }, []); // run only once

  function onFinish() {
    updateSetupStep1(
      // to temp skip the tour
      wheelSettings.SETUP_STEP_EXPENSE_REVIEW_SORT,

      // real code:
      //wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
    console.log(`navigating to Expense Review Tour page`);
    navigate("/expense-review-tour");
  }

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <MainContainer>
        <ProgressBar progress={7} />
        <CelebrateIcon style={{ maxWidth: "100px" }} />

        <div className="tracker-form-split"></div>
        <div className={"form-wrapper"}>
          <Text3>Awesome!</Text3>
          <Text2>Your account connection was successful.</Text2>
          <div></div>
          <Text3 size={1.25}>
            Let's dive into your personalized Money Wheel!
          </Text3>
          <SingleButtonFooter
            onPrevButton={(e) => {}}
            onNextButton={(e) => onFinish(e)}
            disabledPrevButton={true}
            disabledNextButton={false}
            nextBtnText={"Check it out!"}
          />
        </div>
      </MainContainer>
      <GradientSection />
    </div>
  );
}

//const Text2 = styled.div`
//font-family: TitleFont;
//font-size: 1.25rem; /* 20/16 */
//font-weight: 600;
//line-height: 2.5625rem; /* 41/16 */
//`;

const MainContainer = styled.div`
  background-color: #eef7ee;
  width: 100vw;
  height: 60vh;
  border-radius: 0 0 60% 60%/ 0 0 30% 30%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

const GradientSection = styled.div`
  width: 100vw;
  height: 60vh;
  background: linear-gradient(to bottom, #69cd74 0%, #d9f7bd 100%);
  position: absolute;
  top: 40vh;
  z-index: 0;
`;

const Text2 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 1.25rem; /* 20/16 */
  font-weight: 400;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
  width: 90%;
`;

const Text3 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: ${(props) => (props.size ? props.size : 1.75)}rem; /* 20/16 */
  font-weight: 800;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
  width: 90%;
`;

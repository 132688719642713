// constants for MoneyWheel's layout of icons and text in the wheel.

export const Text_Below_Position_Angle_Map = {
  NW: 0.77,
  N: 0.5,
  NE: 0.22,
  E: -0.02,
  SE: -0.26,
  S: -0.5,
  SW: -0.735,
  W: 1.02,
};

export const Text_Above_Position_Angle_Map = {
  NW: 0.75,
  N: 0.5,
  NE: 0.25,
  E: -0.02,
  SE: -0.27,
  S: -0.5,
  SW: -0.73,
  W: 1.02,
};

export const Radius_Map = {
  NW: 126,
  N: 120,
  NE: 126,
  E: 135,
  SE: 137,
  S: 140,
  SW: 137,
  W: 135,
};

export const Size_Map = {
  NW: 40,
  N: 49 /* adjust for wrong-sized icon for family */,
  NE: 40,
  E: 40,
  SE: 40,
  S: 40,
  SW: 40,
  W: 40,
};

import styled from "styled-components";

import ImgBack from "../assets/BackgroundImages/LoginBack.svg";
import ImgMiddle from "../assets/BackgroundImages/LoginMiddle.svg";
import ImgFront from "../assets/BackgroundImages/LoginFront.svg";

import { MAX_WIDTH } from "../../styles/styledLoginComponents";

export default function GreenGradientBackground({ bottom = false }) {
  return (
    <GradientBackground bottom={bottom} className="start">
      <GradientBackground bottom={bottom} className="middle">
        <GradientBackground className="front"></GradientBackground>
      </GradientBackground>
    </GradientBackground>
  );
}

const GradientBackground = styled.div`
  background-image: url(${ImgBack});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  height: 100svh;
  width: 100vw;
  max-width: ${MAX_WIDTH}px;
  position: absolute;
  @media (max-width: ${MAX_WIDTH}px) {
    left: -5vw;
  }
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: -1;
  &.start {
    ${(props) => (props.bottom ? "transform: scaleY(-1);" : "")}
  }
  &.middle {
    background-image: url(${ImgMiddle});
    left: 0;
    height: 80%;
  }
  &.front {
    background-image: url(${ImgFront});
    left: 0;
    height: 92%;
  }
`;

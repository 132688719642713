import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import { LikeOutlined, LikeFilled } from "@ant-design/icons";
import { PointerStyle } from "../../styles/styledCommunity";

const LikeControl = ({ postId, likes }) => {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  const [numLikes, setNumLikes] = useState(likes.length);
  const [isLiked, setIsLiked] = useState(false);
  const [existingLikeId, setExistingLikeId] = useState(0);
  console.log("postId: " + postId + " likes: " + likes);
  const { currentUser } = useUser();
  console.log("currentUser: " + currentUser);

  const handleLike = async () => {
    let unlike = false;

    if (isLiked) {
      console.log("isLiked so unliking");
      unlike = true;
    } else {
      console.log("!isLiked so liking");
      const data = {
        post_id: postId,
      };
      const response = await backend.post("/v1/community/like", data);
      if (checkResponseFail(response, "Failed to create like:")) {
        if (response.hasOwnProperty("existing_id")) {
          // We already liked this.
          console.log(
            "Tried to create duplicate like with existing id: " +
              response.existing_id,
          );
          setIsLiked(true);
          setExistingLikeId(response.existing_id);
          unlike = true;
        }
      } else {
        setIsLiked(true);
        console.log("Created like");
        const newLikes = numLikes + 1;
        setNumLikes(newLikes);
        const newLikeId = response.id;
        setExistingLikeId(newLikeId);
        setIsLiked(true);
      }
    }

    if (unlike) {
      const url = "/v1/community/like?id=" + existingLikeId;
      console.log("deleting like url: " + url);
      const response = await backend.delete(
        "/v1/community/like?id=" + existingLikeId,
      );

      if (response.success) {
        const newLikes = numLikes - 1;
        setNumLikes(newLikes);
        setIsLiked(false);
        setExistingLikeId(0);
      } else {
        console.warn("Failed to delete like: ", response);
      }
    }
  };

  console.log(
    "isLiked: " +
      isLiked +
      " numLikes: " +
      numLikes +
      " existingLikeId: " +
      existingLikeId,
  );

  const getMyLikeId = async (postId) => {
    let myLikeId = 0;

    const response = await backend.get(
      "/v1/community/like?post_id=" + postId + "&user_id=" + currentUser.id,
    );

    if (checkResponseFail(response, "Failed to get like:")) return;

    if (response.items.length > 0) {
      myLikeId = response.items[0].id;
      console.log("Found my like with id: " + myLikeId);
    }

    if (myLikeId !== existingLikeId) {
      setExistingLikeId(myLikeId);
      setIsLiked(myLikeId > 0);
    }
  };

  getMyLikeId(postId);

  return (
    <div onClick={() => handleLike()}>
      <PointerStyle>
        {isLiked ? <LikeFilled /> : <LikeOutlined />}{" "}
        {numLikes === 0 ? "" : numLikes}
      </PointerStyle>
    </div>
  );
};

export default LikeControl;

import { MoneyFlowHeaderNoMenu } from "../../components/PageComponents/MoneyFlowHeader";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import "../../styles/IPadLook.css";

import ResetContent from "./ResetContent";

const PasswordReset = () => {
  return (
    <div className="screen">
      <MoneyFlowHeaderNoMenu />
      <ResetContent />
    </div>
  );
};

export default PasswordReset;

import styled from "styled-components";

const Footer = styled.div``;

const FooterContent = styled.div`
  text-align: center;
  margin: auto;
`;

const Copyright = styled.div`
  /* © 2024 MoneyFlow Organization, Inc. */
`;

// Using require() is efficient.  Even if every page includes this footer,
// require() will run only once: the first time this module is loaded.
// package.json will be loaded, parsed, and its value will be cached
// in the packageJson object.
const packageJson = require("../../../package.json");
const packageJsonVersion = packageJson.version;

function MoneyFlowFooter() {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <Footer>
      <FooterContent>
        <Copyright>
          Version {packageJsonVersion}&nbsp;&nbsp; © {year} MoneyFlow
          Organization, Inc.
        </Copyright>
      </FooterContent>
    </Footer>
  );
}

export default MoneyFlowFooter;

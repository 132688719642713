import styled from "styled-components";

export const Spacer = styled.div`
  height: calc(16vh - 1.52vh);
  width: 20%;
`;

export const IconPlacer = styled.div`
  position: absolute;
  top: calc(16vh - 1.52vh - 75px);
`;

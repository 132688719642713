import { daysSince } from "./timeDate";
import backend from "./backend";

// IN: lastReviewMonth: 1..12
// IN: month: 0..11
//
// return false if the month and year are the same as the last review
// because this means that we have already visited during this month and year
function alreadyShowedMonthReview(
  lastReviewMonth,
  lastReviewYear,
  month,
  year,
) {
  return (
    lastReviewMonth &&
    lastReviewMonth === month + 1 &&
    lastReviewYear &&
    lastReviewYear === year
  );
}

// IN: month: 0..11: today's month
// IN: year: 4 digit year (eg 2024): today's year
//
// IN: alwaysShow: boolean.  While testing, make this true, to always show the
//     End of Month Review
// IN: setCreatedDateToPrevMonth: boolean.  While testing, make this true, to
//     set the createdDate to the last day of the previous month, for this function.
//
// returns (boolean) whether it's time to review the previous month
export async function getMonthReviewStatus(
  month,
  year,
  alwaysShow = false,
  setCreatedDateToPrevMonth = false,
) {
  console.log(`getMonthReviewStatus(): month=${month} year=${year}`);
  const response = await backend.get("/v1/money_wheel_settings");
  if (response.items) {
    const item = response.items[0];
    if (alwaysShow) return true;
    let createdDate;
    if (setCreatedDateToPrevMonth) {
      const now = new Date();
      // day=0 to get last day of previous month
      createdDate = new Date(now.getFullYear(), now.getMonth(), 0);
      console.log(`Last Day Of Previous Month is ${createdDate}`);
    } else {
      createdDate = new Date(item.created_datetime);
    }
    console.log(`createdDate month=${createdDate.getMonth()}`); // 0..11
    console.log(`createdDate year=${createdDate.getFullYear()}`);
    console.log(
      `last_review_month= ${item.last_review_month}   last_review_year= ${item.last_review_year}`,
    );
    if (
      alreadyShowedMonthReview(
        item.last_review_month,
        item.last_review_year,
        month,
        year,
      ) ||
      // or if the user account was created this month,
      (createdDate.getMonth() === month &&
        createdDate.getFullYear() === year) ||
      // or if account is less than 10 days old
      daysSince(createdDate) < 10
    ) {
      // then we won't need to review until next month
      return false;
    }
    return true;
  }
  return false;
}

// IN: month: 1..12
//
// tell server that user has seen the End of Month Review for month, year.
export async function updateMonthReview(month, year, id) {
  const response = await backend.patch("/v1/money_wheel_settings", {
    last_review_month: month, // 1..12
    last_review_year: year,
    id: id,
  });
  console.log("555");
  console.log(response);
}

import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CFTSetup from "./pages/CFTSetup";
import ExpenseReviewInfoDisplay from "./pages/ExpenseReview/ExpenseReviewInfoDisplay";
import ExpenseReview from "./pages/ExpenseReview";

import Login from "./pages/Login";
import Registration from "./pages/Registration";
import RegisterPayment from "./pages/Registration/RegisterPayment";
import ActiveTracker from "./pages/ActiveTracker";

import UserProvider from "./state/UserProvider";
import SettingsProvider from "./state/SettingsProvider";
import { PlaidProvider } from "./pages/Plaid/index.tsx";
import NotifyFlagProvider from "./pages/Congratulations/NotifyFlagProvider";
import { usePollingA } from "./pages/ActiveTracker/PollingProvider";

import LinkAccount from "./pages/Plaid/LinkAccount";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfilePage from "./pages/ProfilePage";
import AccountsPage from "./pages/AccountsPage";
import ScorePage from "./pages/Score";
import IncomePage from "./pages/Income";
import Congratulations from "./pages/Congratulations";
import Admin from "./pages/admin";
import AdminNotificationsContent from "./pages/admin/pageAdminNotificationsContent";
import AdminUsersContent from "./pages/admin/pageAdminUsersContent";
import AdminTransactionsContent from "./pages/admin/pageAdminTransactionsContent";
import Community from "./pages/Community/Community";
import Marketplace from "./pages/Marketplace/Marketplace";
import HelpPage from "./pages/HelpPage";
import HelpThankYou from "./pages/HelpPage/HelpThankYou";
import Posts from "./pages/Community/Posts";
import Replies from "./pages/Community/Replies";
import CreditWheelPage from "./pages/CreditWheel";
import GoalsWheelPage from "./pages/GoalsWheel";
import Tracker from "./pages/Tracker/TrackerDemo";
import { Notifications } from "react-push-notification";
import GlobalStyle from "./styles/GlobalStyle";
import PasswordForgot from "./pages/PasswordForgot";
import PasswordReset from "./pages/PasswordReset";

function App() {
  const { stopPolling } = usePollingA();
  useEffect(() => {
    /*
    const handleBeforeUnload = (event) => {
      console.log("User is about to leave the page");

      event.preventDefault();

      // This triggers the browser's default confirmation dialog
      event.returnValue = "Are you sure you want to leave?";
    };
    */

    const handleUnload = () => {
      console.log("User has left the page or quit the app.");
      // cleanup and send data to the server
      stopPolling();
    };

    //window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      //window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <NotifyFlagProvider>
        <UserProvider>
          <SettingsProvider>
            <PlaidProvider>
              <Notifications />
              <Routes>
                <Route
                  path="/admin-transactions"
                  element={<AdminTransactionsContent />}
                />
                <Route
                  path="/admin-notifications"
                  element={<AdminNotificationsContent />}
                />
                <Route path="/admin-users" element={<AdminUsersContent />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/" element={<Login />} />
                <Route path="/money-wheel-setup" element={<CFTSetup />} />
                <Route path="/tracker" element={<Tracker />} />
                <Route path="/money-wheel" element={<ActiveTracker />} />
                <Route path="/community" element={<Community />} />
                <Route path="/replies/:postId" element={<Replies />} />
                <Route path="/posts/:groupId" element={<Posts />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/help" element={<HelpPage />} />
                <Route
                  path="/help-thank-you/:ticketId"
                  element={<HelpThankYou />}
                />
                <Route path="/credit-wheel" element={<CreditWheelPage />} />
                <Route path="/goals-wheel" element={<GoalsWheelPage />} />
                <Route
                  path="/expense-review-info"
                  element={<ExpenseReviewInfoDisplay />}
                />
                <Route
                  path="/expense-review-tour"
                  element={<ExpenseReview />}
                />
                <Route path="/expense-review" element={<ExpenseReview />} />
                <Route path="/congratulations" element={<Congratulations />} />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/recover-password" element={<PasswordForgot />} />
                <Route path="/reset-password/*" element={<PasswordReset />} />
                <Route path="/link-account" element={<LinkAccount />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/profile-page" element={<ProfilePage />} />
                <Route path="/accounts-page" element={<AccountsPage />} />
                <Route path="/score" element={<ScorePage />} />
                <Route path="/income" element={<IncomePage />} />
                <Route
                  path="/payment-registration"
                  element={<RegisterPayment />}
                />
              </Routes>
            </PlaidProvider>
          </SettingsProvider>
        </UserProvider>
      </NotifyFlagProvider>
    </>
  );
}

export default App;

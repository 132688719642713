import styled from "styled-components";

export default function PageSelector({ showPlanned, selectedIndex, setTab }) {
  //This is jus tthe visual component, it needs the functionality to be added
  const selected = [false, false, false];
  selected[selectedIndex] = true;
  return (
    <Container>
      <RadioElement
        selected={selected[0]}
        className="left"
        onClick={() => {
          setTab("Planned");
        }}
      >
        {showPlanned ? "Planned" : "Income"}
      </RadioElement>
      <RadioElement
        selected={selected[1]}
        onClick={() => {
          setTab("Spending");
        }}
      >
        Spending
      </RadioElement>
      <RadioElement
        selected={selected[2]}
        className="right"
        onClick={() => {
          setTab("Overview");
        }}
      >
        Overview
      </RadioElement>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  box-shadow: 0px 2px 8px 0px #d4d4d4cc;
  width: 331px;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
`;
const RadioElement = styled.button`
  all: unset;
  cursor: pointer;
  outline: inherit; //does this do anything?

  width: 110px;
  height: 34px;
  background: #f6f6f6;
  color: #919191;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  ${(props) =>
    props.selected &&
    `{
    color: #25B764;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px #D4D4D4CC;
  }`}

  &.left {
    border-right: 1px solid #c4c4c4;
    border-radius: 100px 0 0 100px;
  }
  &.right {
    border-left: 1px solid #c4c4c4;
    border-radius: 0 100px 100px 0;
  }
`;

import { Form, Input } from "antd";
import { useState, useEffect } from "react";
import styled from "styled-components";

const defaultRulesCoolFormInput = [
  {
    required: false,
    message: "This field is required.",
  },
  { max: 80, message: "Can be at most 80 characters." },
  {
    // disallows:
    // To simplify the initial coding and to avoid potential
    // HTML injection attacks or escaping issues,
    // disallow these special characters: < > ' " \ /
    //
    // Spaces are allowed.
    //
    // Note: in the pattern below, - [ ]  are escaped with \
    pattern: /^[a-zA-Z0-9~!@#$%^&*()\-_=+\[\]{}|;:?., ]+$/,
    message:
      "Can include letters, numbers, spaces, and most symbols, but not < > ' \" \\ /.",
  },
];

export default function CoolFormInput({
  name,
  rules = defaultRulesCoolFormInput,
  placeholder,
  hasInitialValue = false, // true, if we have given the form input an initial value
  required = false,
  password = false,
  minMax = [0, 80],
}) {
  const [moveText, setMovetext] = useState(false);
  useEffect(() => {
    if (hasInitialValue) {
      setMovetext(true);
    }
  }, [hasInitialValue]);

  const handleInputChange = (e) => {
    if (e.target.value) {
      setMovetext(true);
    } else {
      setMovetext(false);
    }
  };
  return (
    <MainContainer>
      <SmallText>{moveText ? placeholder : ""}</SmallText>
      <Form.Item name={name} rules={rules}>
        {password ? (
          <StyleInputPassword
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        ) : (
          <StyleInput onChange={handleInputChange} placeholder={placeholder} />
        )}
      </Form.Item>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  position: relative;
  margin-top: 35px;
`;
const SmallText = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: white;
  flex-grow: 0;
  display: inline-block;
  padding: 0 4px;
  color: #68cd74;
  font-size: 0.75rem;
  max-height: 21px;
`;
const StyleInput = styled(Input)`
  &:focus {
    border-color: #68cd74 !important;
  }
  border-color: #d9f7bd !important;
  height: 42px;
  border-radius: 12px;
`;
const StyleInputPassword = styled(Input.Password)`
  &.ant-input-affix-wrapper {
    border-color: #d9f7bd;
  }
  &.ant-input-affix-wrapper-focused {
    border-color: #68cd74;
  }
  height: 42px;
  border-radius: 12px;
`;

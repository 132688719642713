import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApp } from "../../state/AppProvider";
import axios from "axios";

import { useUser } from "../../state/UserProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { isGoodBrowser } from "../../functions/platform";
import { prettyPrintDateTime } from "../../functions/timeDate";
import { textToHtml, hasContent } from "../../functions/string";

import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import CoolFormInputEmail from "../../components/UI/CoolFormInputEmail";
import CoolFormInputName from "../../components/UI/CoolFormInputName";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import * as SP from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";
import { Form, Input, Select, Button } from "antd";
const { TextArea } = Input;

const ZENDESK_SUBDOMAIN = "moneyfloworganizationsupport";

function HelpPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPage } = location.state || {};
  console.log("HelpPage() received currentPage =", currentPage);
  //const { n, s, b } = location.state || {};

  const [form1] = Form.useForm();
  const { currentUser } = useUser();
  console.log("This is from the UserProvider: currentUser=", currentUser);

  const { lastErrorMsg } = useApp();
  const checkResponseFail = useCheckResponseFail();
  const [formInitialValues, setFormInitialValues] = useState({});

  async function init() {
    var fields = {};
    if (currentUser) {
      fields.first_name = currentUser.first_name;
      fields.last_name = currentUser.last_name;
      fields.email_address = currentUser.email_address;
    }

    const pathToTopicValue = {
      "/money-wheel": "MoneyWheel",
      "/credit-wheel": "CreditWheel",
      "/goals-wheel": "GoalsWheel",
      "/community": "Community",
      "/marketplace": "MarketPlace",
      "/profile-page": "Profile",
      "/accounts-page": "Accounts",
      "/score": "Score",
      "/income": "Income",
    };
    fields.topic = pathToTopicValue[currentPage] || "Other";

    form1.setFieldsValue(fields);
    setFormInitialValues({ ...fields });
  }

  useEffect(() => {
    init();
  }, []);

  async function onFinish(values) {
    console.log("HelpPage: onFinish()");
    console.log(`values.first_name : ${values.first_name}`);
    console.log(`values.last_name : ${values.last_name}`);
    console.log(`values.email_address : ${values.email_address}`);
    console.log(`values.topic : ${values.topic}`);
    console.log(`values.comment : ${values.comment}`);

    // Zendesk Custom Field IDs
    const ID_PREVIOUS_PAGE = 30534140879635;

    // If data is sent through the request.comment.body, then
    // Keep the line breaks and spacing in the template literal below,
    // since this is the format that the customer agent will see.

    // If data is sent through the request.comment.html_body, then
    // use html tags for formatting.

    // Send currentPage, since topic is not always the same.
    const dataBody = `
      <b>===================================</b><br><br>

      <h3>Info from MoneyFlow app:</h3><br>

      <b>◀️ Previous page before going to Help page:</b>
        ${currentPage}<br><br>

      <b>❌ Last error message displayed to user:</b>
        ${lastErrorMsg ? lastErrorMsg : "none"}<br><br>

      <b>📱 UserAgent:</b>  ${window.navigator.userAgent}<br><br>
      <b>📐 Viewport dimensions:</b>  ${window.innerWidth} x ${window.innerHeight}<br><br>
      <b>💻 Is supported browser:</b>  ${isGoodBrowser() ? "yes" : "no"}<br><br>

      <b>===================================</b><br><br>

      <h3>Logged into MoneyFlow app as:</h3><br>

      <b>🧍 Name:</b>  ${currentUser.first_name} ${currentUser.last_name}<br>
      <b>✉️ Email:</b>  ${currentUser.email_address}<br>
      <b>📞 Phone number:</b>  ${currentUser.phone_number}<br>
      <b>️🌎 Location:</b>  CA, USA<br><br>

      <b>️🗣️ Language:</b>  English<br><br>

      <b>✉️ Last login:</b>  ${prettyPrintDateTime(currentUser.last_login)}<br><br>

      <b>===================================</b><br><br>

      <h3>Help Page:</h3><br>

      <b>Date sent:</b>  ${prettyPrintDateTime(new Date())}<br>
      <b>Sender name:</b>  ${values.first_name} ${values.last_name}<br>
      <b>Sender email:</b>  ${values.email_address}<br>
      <b>Subject:</b>  ${values.topic}<br><br>

      <b>===================================</b><br><br>

      <h1>Comment:</h1><br>

      ${textToHtml(values.comment)}
    `;

    // Tip: don't have trailing comma for JSON format.
    // In other words, don't put a comma after any
    // last name-value pair.
    const data = {
      request: {
        comment: {
          html_body: dataBody,
        },
        custom_fields: [
          {
            id: ID_PREVIOUS_PAGE,
            value: currentPage,
          },
        ],
        requester: {
          name: values.first_name + " " + values.last_name,
          email: values.email_address,
        },
        subject: values.topic,
      },
    };

    let response;
    const url = `https://${ZENDESK_SUBDOMAIN}.zendesk.com/api/v2/requests`;
    console.log("post " + url);
    console.log("data: ");
    console.log(data);

    response = await axios.post(url, JSON.stringify(data), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (
      !checkResponseFail(
        response,
        "We couldn't process your help request.  Please try again later.",
      )
    ) {
      console.log("Help form submitted successfully");
      const ticketId = response.data.request.id;
      console.log(`ticketId is ${ticketId}`);
      navigate(`/help-thank-you/${ticketId}`);
    }
  }

  function navBack() {
    navigate(currentPage ? currentPage : "/money-wheel");
  }

  function onFinishFailed(errorInfo) {}

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <SP.ScrollingAboveBottomNavBar>
        <ErrorNoticeBar />
        <Form
          style={{ maxWidth: 430, width: "100%" }}
          layout="vertical"
          name="form_registration"
          initialValues={{
            remember: true,
            topic: "MoneyWheel",
          }}
          form={form1}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <SP.FormTitle>Help</SP.FormTitle>
          <h3>Please share your thoughts about the MoneyFlow App, such as:</h3>
          <ul>
            <li>Overall impressions</li>
            <li>Need help with any questions or concerns</li>
            <li>Bugs and problem reports</li>
            <li>Suggestions for improvement</li>
          </ul>
          <SP.FormItemContainer>
            <CoolFormInputName
              name="first_name"
              placeholder="First Name"
              hasInitialValue={hasContent(formInitialValues.first_name)}
            />
            <CoolFormInputName
              name="last_name"
              placeholder="Last Name"
              hasInitialValue={hasContent(formInitialValues.last_name)}
            />
            <CoolFormInputEmail
              placeholder="Email"
              hasInitialValue={true}
              required={true}
            />
            <Form.Item name="topic" label="Topic" hasFeedback>
              <Select
                options={[
                  { value: "MoneyWheel", label: "Money Wheel" },
                  { value: "CreditWheel", label: "Credit Wheel" },
                  { value: "GoalsWheel", label: "Goals Wheel" },
                  { value: "Community", label: "Community" },
                  { value: "MarketPlace", label: "Market Place" },
                  { value: "Profile", label: "Profile" },
                  { value: "Accounts", label: "Accounts" },
                  { value: "Score", label: "MoneyFlow Score" },
                  { value: "Income", label: "Income" },
                  { value: "Other", label: "Other" },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item name="comment" label="Comment" hasFeedback>
              <TextArea
                showCount
                maxLength={1000}
                placeholder="Please enter your comment here."
                style={{
                  height: 120,
                  resize: "none",
                }}
              />
            </Form.Item>
          </SP.FormItemContainer>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Feedback
            </Button>
          </Form.Item>
        </Form>
      </SP.ScrollingAboveBottomNavBar>
      <BottomNavBar />
    </div>
  );
}

export default HelpPage;

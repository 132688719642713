//couple icons
import { ReactComponent as MarriedIcon } from "../../../assets/img/SetupOutlineIcons/Version=Couple_Fill.svg";
import { ReactComponent as MarriedPlusKid } from "../../../assets/img/SetupOutlineIcons/Version=Couple_Kid_3Plus_Fill.svg";
import { ReactComponent as Married1Kid } from "../../../assets/img/SetupOutlineIcons/Version=Couple_Kid_1_Fill.svg";
import { ReactComponent as Married2Kid } from "../../../assets/img/SetupOutlineIcons/Version=Couple_Kid_2_Fill.svg";
import { ReactComponent as Married3Kid } from "../../../assets/img/SetupOutlineIcons/Version=Couple_Kid_3_Fill.svg";

//single icons
import { ReactComponent as SinglePersonIcon } from "../../../assets/img/SetupOutlineIcons/Version=Single_Fill.svg";
import { ReactComponent as SinglePersonPlusKid } from "../../../assets/img/SetupOutlineIcons/Version=Single_Kid_3Plus_Fill.svg";
import { ReactComponent as SinglePerson1Kid } from "../../../assets/img/SetupOutlineIcons/Version=Single_Kid_1_Fill.svg";
import { ReactComponent as SinglePerson2Kid } from "../../../assets/img/SetupOutlineIcons/Version=Single_Kid_2_Fill.svg";
import { ReactComponent as SinglePerson3Kid } from "../../../assets/img/SetupOutlineIcons/Version=Single_Kid_3_Fill.svg";

export const BasicFamilyIcon = ({ maritalStatus, dependents }) => {
  console.log(maritalStatus);
  let icon;
  const switchIcon = (val) => {
    if (val === "single") {
      console.log("Single person!");
      console.log("AAaaAAAAAAAaa");
      icon = <SinglePersonIcon className="categoryIcon" />;
      if (dependents) {
        if (dependents === 1) {
          icon = <SinglePerson1Kid className="categoryIcon" />;
        } else if (dependents === 2) {
          icon = <SinglePerson2Kid className="categoryIcon" />;
        } else if (dependents === 3) {
          icon = <SinglePerson3Kid className="categoryIcon" />;
        } else if (dependents > 3) {
          icon = <SinglePersonPlusKid className="categoryIcon" />;
        }
      }
    } else {
      console.log("Partner person!");
      console.log("BBBBBBbbbbbbBBBBB");
      icon = <MarriedIcon className="categoryIcon" />;
      if (dependents) {
        if (dependents === 1) {
          icon = <Married1Kid className="categoryIcon" />;
        } else if (dependents === 2) {
          icon = <Married2Kid className="categoryIcon" />;
        } else if (dependents === 3) {
          icon = <Married3Kid className="categoryIcon" />;
        } else if (dependents > 3) {
          icon = <MarriedPlusKid className="categoryIcon" />;
        }
      }
    }
  };

  switchIcon(maritalStatus);

  return <div>{icon}</div>;
};

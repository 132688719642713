import CoolFormInput from "./CoolFormInput";

export default function CoolFormInputEmailOrPhone({
  name,
  placeholder,
  hasInitialValue = false,
  required = false,
}) {
  return (
    <CoolFormInput
      name={name}
      placeholder={placeholder}
      hasInitialValue={hasInitialValue}
      rules={[
        {
          required: required,
          message: "Please enter your email or phone.",
        },
        // take the union of the min and max
        // for email and phone number, since it can be either
        {
          min: 5,
          message: "Email or phone number must be at least 5 characters.",
        },
        {
          max: 80,
          message: "Email or phone number must be at most 80 characters.",
        },
        {
          validator: (_, value) => {
            // match email or phone number
            const emailRegex =
              /^[-~+._0-9a-zA-Z]+@[-~+._0-9a-zA-Z]+\.[-~+._0-9a-zA-Z]+$/;
            if (
              (!/^[0-9]+/.test(value) && emailRegex.test(value)) ||
              /^[0-9+\-() ]+$/.test(value)
            ) {
              // it's a valid email or phone number
              return Promise.resolve();
            }

            // The rest of this function is guessing what the user
            // was trying to input into the field, and then reporting
            // what mistake they probably made.
            if (/@/.test(value) || /\.(com|net|org|edu)$/.test(value)) {
              // probably email, since it contains an `@`
              // or ends with a common top-level domain, like .com
              if (!/@/.test(value)) {
                return Promise.reject("Email is missing @ symbol.");
              } else if (/^\d/.test(value)) {
                // begins with a number
                return Promise.reject("Email can not start with a number.");
              } else {
                return Promise.reject(
                  "Letters, numbers, +, -, ~, dot (.) or underscore (_) only for emails.",
                );
              }
            }
            if (/\d{5,}/.test(value)) {
              // probably a telephone number, since it contains at least 5 digits
              return Promise.reject(
                "Phone number can only include numbers and the symbols + - ( ) and spaces.",
              );
            }

            // not sure whether email or phone number
            return Promise.reject(
              value && value.length >= 5
                ? "Invalid email or phone number."
                : "",
            );
          },
        },
      ]}
    />
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import { Tooltip } from "antd";
import { PointerStyle } from "../../styles/styledCommunity";
import styled from "styled-components";
import "../../styles/IPadLook.css";

export default function Community() {
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState([]);
  const checkResponseFail = useCheckResponseFail();

  const getGroups = async () => {
    const response = await backend.get("/v1/community/group");

    if (checkResponseFail(response, "Failed to get groups:")) return;

    let groupData = [];

    if (response.items) {
      console.log("Got " + response.items.length + " groups.");

      for (let i = 0; i < response.items.length; i++) {
        groupData[i] = {};
        groupData[i].id = response.items[i].id;
        groupData[i].name = response.items[i].name;
        groupData[i].posts = response.items[i].posts;
      }
    }

    setGroupList(groupData);
  };

  useEffect(() => {
    getGroups();
  }, []);

  const goToPosts = (groupId) => {
    navigate("/posts/" + groupId);
  };

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <h2>Community</h2>
      The MoneyFlow Community is disabled during Early Access.
      {/* XXX TODO
      <CommunityNav>
        <h3>Groups</h3>
        {groupList.map((group) => (
          <div key={group.id} onClick={() => goToPosts(group.id)}>
            <Tooltip title="Posts">
              <PointerStyle>
                {group.name}
                <br />
                <br />
              </PointerStyle>
            </Tooltip>
          </div>
        ))}
      </CommunityNav>
      XXX TODO */}
      <BottomNavBar />
    </div>
  );
}

const CommunityNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

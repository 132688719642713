import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import ProgressBar from "../../components/UI/ProgressBar";
import { InfoDisplay } from "./InfoDisplay";
import { SingleOrMarriedForm } from "./Forms/SingleOrMarriedForm";
import { HowManyDependentsForm } from "./Forms/HowManyDependentsForm";
import { YearOfDependentForm } from "./Forms/YearOfDependentForm";
import { RentOrOwnForm } from "./Forms/RentOrOwnForm";
import { HowManyVehiclesForm } from "./Forms/HowManyVehiclesForm";
import "../../styles/IPadLook.css";
import "./CFTSetupScreen.css";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import CircleBackground from "../../components/UI/BackgroundHalfCircle";

function CFTSetup() {
  const checkResponseFail = useCheckResponseFail();

  const { currentUser, dispatchUser } = useUser();
  console.log(
    "CFTSetup: 0: This is from the UserProvider: currentUser=",
    currentUser,
  );
  const { wheelSettings, dispatchSettings } = useSettings();
  console.log(
    "CFTSetup: 0: This is from the SettingsProvider: wheelSettings=",
    wheelSettings,
  );

  const navigate = useNavigate();

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  console.log(`viewport = ${viewportWidth} x ${viewportHeight}`);

  const pageList = [
    "info1",
    "SingleOrMarriedForm",
    "HowManyDependentsForm",
    "YearOfDependentForm",
    "RentOrOwnForm",
    "HowManyVehiclesForm",
  ];

  const CFTForm = [
    <InfoDisplay category="" onFinish={onFinish} previousPage={previousPage} />,
    <SingleOrMarriedForm
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      previousPage={previousPage}
    />,
    <HowManyDependentsForm
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      previousPage={previousPage}
    />,
    <YearOfDependentForm
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      previousPage={previousPage}
      numDependents={wheelSettings.dependent_count}
    />,
    <RentOrOwnForm
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      previousPage={previousPage}
    />,
    <HowManyVehiclesForm
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      previousPage={previousPage}
      isNewPage={isNewPage}
    />,
  ];
  // const CircleGradients = [
  //   ["#caf8f5", "#00c0bb"],
  //   ["#caf8f5", "#00c0bb"],
  //   ["#caf8f5", "#00c0bb"],
  //   ["#caf8f5", "#00c0bb"],
  //   ["#c1ddff", "#4f92ff"],
  //   ["#ecd8ff", "#b31dff"],
  // ];
  const CircleGradients = [
    ["#d9f7bd", "#69cd74"],
    ["#d9f7bd", "#69cd74"],
    ["#d9f7bd", "#69cd74"],
    ["#d9f7bd", "#69cd74"],
    ["#d9f7bd", "#69cd74"],
    ["#d9f7bd", "#69cd74"],
  ];
  // const CircleGradients = [
  //   ["#ffffff", "#ffffff"],
  //   ["#ffffff", "#ffffff"],
  //   ["#ffffff", "#ffffff"],
  //   ["#ffffff", "#ffffff"],
  //   ["#ffffff", "#ffffff"],
  //   ["#ffffff", "#ffffff"],
  // ];

  // currentIndex:  the index of the current page in pageList
  const [currentIndex, setCurrentIndex] = useState(wheelSettings.setup_step);

  console.log(`currentIndex = ${currentIndex}`);

  // So updateSetupStep() must happen AFTER pageList is updated.
  function updateSetupStep() {
    if (currentIndex >= pageList.length) {
      console.log("CFTSetup is complete.  Going to Plaid setup.");
      return;
    }

    console.log(
      `updateSetupStep: setup_step is ${wheelSettings.setup_step} (${
        pageList[wheelSettings.setup_step]
      })`,
    );

    // Make setup_step catch up to the same page as currentIndex.
    if (wheelSettings.setup_step < currentIndex) {
      console.log(`updateSetupStep: new_setup_step is ${currentIndex}`);
      updateSetupStep1(
        currentIndex,
        currentUser.money_wheel_settings_id,
        dispatchSettings,
      );
    }
  }
  updateSetupStep();

  useEffect(() => {
    if (pageList.length <= currentIndex) {
      navigate("/link-account"); // go to Plaid setup
    }
  }, [currentIndex, navigate, pageList]);

  // returns boolean whether the current setup page has never been completed by the user
  // (ie it's a new page for the user)
  function isNewPage() {
    // settings.setup_step
    //   is the *first page* the user has not yet completed in the setup process.
    console.log("isNewPage()");
    console.log(
      `    setup_step is ${wheelSettings.setup_step}  ${pageList[wheelSettings.setup_step]}`,
    );
    console.log(
      `    currentIndex is ${currentIndex}  ${pageList[currentIndex]}`,
    );
    return currentIndex >= wheelSettings.setup_step;
  }

  function previousPage() {
    let currentPage = pageList[currentIndex];
    if (
      currentPage === "RentOrOwnForm" &&
      wheelSettings.dependent_count === 0
    ) {
      // skip YearOfDependents page
      setCurrentIndex((index) => index - 2);
    } else {
      setCurrentIndex((index) => (index > 0 ? index - 1 : 0));
    }
  }

  function nextPage(currentPage, numSkipPages) {
    // Settings.setup_step is the first page that has not been completed yet
    console.assert(
      currentIndex <= wheelSettings.setup_step,
      "currentIndex must be <= setup_step",
    );

    if (currentPage === "HowManyVehiclesForm") {
      updateSetupStep1(
        wheelSettings.SETUP_STEP_PLAID,
        currentUser.money_wheel_settings_id,
        dispatchSettings,
      );
      navigate("/link-account"); // go to Plaid setup
    } else {
      setCurrentIndex((index) => index + 1 + numSkipPages);
    }
  }

  async function onFinish(values) {
    console.log("onFinish() has values:");
    console.log(values);
    let currentPage = pageList[currentIndex];
    console.log("currentPage: ", currentPage);
    let numSkipPages = 0;

    if (currentPage !== "info1") {
      if (!values._reactName) {
        if (
          currentPage === "RentOrOwnForm" ||
          currentPage === "SingleOrMarriedForm"
        ) {
          // save to UserProvider
          dispatchUser({
            type: "CFT_SETUP",
            payload: {
              values,
              currentPage,
            },
          });
        }
      }

      let data;
      let response;
      let exists;

      console.log("onFinish: 0:  currentUser=", currentUser);

      let user_id = currentUser.id;
      console.log("onFinish() user_id=", user_id);

      switch (currentPage) {
        case "SingleOrMarriedForm":
          data = { id: user_id, marital_status: values.marital_status };
          response = await backend.patch("/v1/user", data);
          checkResponseFail(response, "Failed to patch marital_status:");
          break;

        case "RentOrOwnForm":
          data = { id: user_id, home_ownership: values.home_ownership };
          response = await backend.patch("/v1/user", data);
          checkResponseFail(response, "Failed to patch home_ownership:");
          break;

        case "HowManyDependentsForm":
          console.log(
            "onFinish: case HowManyDependentsForm: values=",
            values.Dependents,
          );

          // If the new numDep < old numDep
          //  then DELETE the excess dependents from the array.
          for (
            let i = values.Dependents;
            i < currentUser.dependents.length;
            i++
          ) {
            // tell backend to delete the dependents with id=dependentId
            let dependentId = currentUser.dependents[i];
            response = await backend.delete("/v1/dependent?id=" + dependentId);
            checkResponseFail(response, "Failed to DELETE dependent:");
            if (response.success) {
              console.log(
                `Deleted dependent with id = ${dependentId} successfully`,
              );
            }
          }

          // Delete the excess dependents from currentUser.dependents[] and currentUser.dependentAges[].
          // Save to UserProvider.
          dispatchUser({
            type: "CFT_SETUP_HOW_MANY_DEPENDENTS",
            payload: {
              numDependents: values.Dependents,
            },
          });

          // update wheelSettings.dependent_count;
          data = {
            dependent_count: values.Dependents,
            id: currentUser.money_wheel_settings_id,
          };
          response = await backend.patch("/v1/money_wheel_settings", data);
          checkResponseFail(response, "Failed to patch Number of Dependents:");

          // Save to SettingsProvider.
          dispatchSettings({
            type: "NUM_DEPENDENTS",
            payload: {
              dependent_count: values.Dependents,
            },
          });

          if (values.Dependents === 0) {
            // skip YearOfDependents page
            numSkipPages = 1;
          }

          break;

        case "YearOfDependentForm":
          // The list of dependent ids in the user data,
          // is automatically retrieved when you first get currentUser
          // (ie just after user logs in).

          console.assert(currentUser.dependentAges);
          console.assert(
            currentUser.dependentAges.length === currentUser.dependents.length,
          );

          console.log(
            "Number of dependents =",
            currentUser.dependentAges.length,
          );
          const numDependents = currentUser.dependentAges.length;

          let updatedDependentIds = [...currentUser.dependents];

          for (let i = 0; i < values.ages.length; i++) {
            let val = values.ages[i];
            // does this dependent exist already?
            exists = i < currentUser.dependents.length;
            console.log("onFinish: YearOfDependent: exists =", exists);
            if (exists) {
              let prevAge = currentUser.dependentAges[i]; // age of the dependent with this index.
              if (prevAge !== val) {
                // age has changed
                data = {
                  age: val,
                  id: currentUser.dependents[i],
                };
                console.log(
                  `PATCH dependent with id = ${currentUser.dependents[i]}`,
                );
                response = await backend.patch("/v1/dependent", data);
                checkResponseFail(response, "Failed to patch dependent");
              }
            } else {
              // If doesn't exist yet, then create the dependent.
              // NOTE: add the new dependent here instead of in
              // the HowManyDependentsForm, because that would cause 2 API calls:
              // one to POST the new dependent and
              // another one to PATCH later to set the age.
              // Here, we can create the dependent and set the age in just
              // 1 API call.
              data = {
                first_name: "Dependent" + i,
                last_name: currentUser.last_name,
                age: val,
              };
              console.log("onFinish: YearOfDependent: data =", data);
              console.log("POST new dependent");
              response = await backend.post("/v1/dependent", data);

              checkResponseFail(response, "Failed to post dependent");
              if (response.success) {
                let newDependentId = response.id;
                console.log(
                  `onFinish(): created new dependent with dependent id = ${newDependentId}`,
                );
                updatedDependentIds.push(newDependentId);
              }
            }
          }

          // save to UserProvider
          dispatchUser({
            type: "CFT_SETUP_AGE_OF_DEPENDENT",
            payload: {
              ages: values.ages,
              dependentIds: updatedDependentIds,
            },
          });
          break;

        case "HowManyVehiclesForm":
          exists = false; // does the vehicle with id=vehicleID exist in the server?
          let vehicleID = null;
          if (currentUser.vehicles) {
            if (currentUser.vehicles.length >= 1) {
              vehicleID = currentUser.vehicles[0];
              exists = true;
            }
          }
          console.log(`vehicleID is ${vehicleID}`);

          if (values["autosOwned"] === "yes") {
            // if backend already has a vehicle, then skip the post
            if (!exists) {
              // tell backend to create a new vehicle
              let data = {};
              response = await backend.post("/v1/vehicle", data);
              checkResponseFail(response, "Failed to post vehicle:");
              if (response.success) {
                try {
                  // Save to UserProvider.
                  dispatchUser({
                    type: "CFT_SETUP_VEHICLES",
                    payload: {
                      id: response.id,
                    },
                  });
                  console.log(
                    `Added vehicle with id=${response.id} successfully`,
                  );
                } catch (error) {
                  console.error("Error saving vehicleID");
                }
              }
            }
          } else {
            if (exists) {
              // tell backend to delete the vehicle with id=vehicleID
              response = await backend.delete("/v1/vehicle?id=" + vehicleID);
              checkResponseFail(response, "Failed to DELETE vehicle:");
              if (response.success) {
                try {
                  // Save to UserProvider.
                  dispatchUser({
                    type: "CFT_SETUP_VEHICLES",
                    payload: {
                      id: null,
                    },
                  });

                  console.log("Deleted vehicle successfully");
                } catch (error) {
                  console.error("Error deleting vehicleID");
                }
              }
            }
          }

          break;

        default:
      }
    }

    nextPage(currentPage, numSkipPages);
  } /* end onFinish */

  /* XXX TODO Do we need to do some error handling here? */
  function onFinishFailed(errorInfo) {}

  return (
    <div className="screen">
      <CircleBackground gradient={CircleGradients[currentIndex]} />
      <MoneyFlowHeader />
      {pageList[currentIndex] !== "info1" && (
        <ProgressBar
          // progress={Math.floor((currentIndex * 100) / pageList.length)}
          progress={currentIndex}
        />
      )}
      <ErrorNoticeBar />
      <div className="tracker-form-split CFTSetupTracker">
        {CFTForm[currentIndex]}
      </div>
    </div>
  );
}

export default CFTSetup;

import { Button, Form } from "antd";
import { useState, useEffect } from "react";
import {
  SplitEntry,
  GreyTextEntry,
  SplitButton,
  CreateSplitButton,
  AntGreyTextEntry,
  AntSplitEntry,
  GreyDropDown,
  IconAndText,
} from "./splitComponents";
import styled from "styled-components";
import Icon from "@ant-design/icons/lib/components/Icon";

export default function ExpenseEditform({
  form,
  isOpen,
  onFinish,
  closeModal,
  expenseInfo,
  maxSplit,
  doSplit,
  setDoSplit,
  editInfo,
  setEditInfo,
  name,
}) {
  let counterparty = "";
  let amount = "";
  let account_type = "";
  let posted_date = "";
  let originalAmount = "";
  let note = "";
  let label = "";
  const [category, setCategory] = useState(
    expenseInfo.category ? expenseInfo.category : null,
  );

  if (expenseInfo) {
    counterparty = expenseInfo.counterparty;
    amount = expenseInfo.amount;
    account_type = expenseInfo.account_type;
    posted_date = expenseInfo.posted_date;
    originalAmount = expenseInfo.original_amount;
    note = expenseInfo.note ? expenseInfo.note : "";
    label = expenseInfo.label ? expenseInfo.label : "";
  }
  note = form.getFieldValue("note");
  label = form.getFieldValue("label");
  amount = form.getFieldValue("splitAmount");
  console.log("amount: ", amount);
  console.log("originalAmount: ", originalAmount);

  return (
    <Form
      form={form}
      name={name ? name : "editDetailsCircle"}
      onFinish={onFinish}
      onFinishFailed={closeModal}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        {category && (
          <div style={{ marginLeft: "auto" }}>
            <IconAndText category={category} />
          </div>
        )}
        <BigText>
          <p className="number">
            ${amount}
            {amount != originalAmount ? " of $" + originalAmount : ""}
          </p>
          <p>{label !== "" && label !== null ? label : counterparty}</p>
        </BigText>
        {!editInfo && (
          <AddInfoButton type="button" onClick={() => setEditInfo(true)}>
            + Add Label
          </AddInfoButton>
        )}
        <LabeledField>
          <p className="label">{"Date"}</p>
          <p className="value">{posted_date}</p>
        </LabeledField>
        <LabeledField>
          <p className="label">{"Account"}</p>
          <p className="value">{account_type}</p>
        </LabeledField>
        <LabeledField>
          <p className="label">{"Vendor"}</p>
          <p className="value">{counterparty}</p>
        </LabeledField>
        <LabeledField>
          <p className="label">{"Notes"}</p>
          <p className="value">{note}</p>
        </LabeledField>
        {doSplit ? <SplitAmountFormItem maxSplit={maxSplit} /> : null}
        {!editInfo && (
          <AddInfoButton type="button" onClick={() => setEditInfo(true)}>
            + Add Note
          </AddInfoButton>
        )}

        {editInfo && (
          <>
            <AntGreyTextEntry
              label="Expense Label"
              name="label"
            ></AntGreyTextEntry>
            <AntGreyTextEntry label="Notes" name="note"></AntGreyTextEntry>
            {category && (
              <Form.Item
                name="category"
                initialValue={undefined}
                style={{ marginLeft: "auto" }}
              >
                <GreyDropDown
                  category={category}
                  setCategory={(value) => {
                    setCategory(value);
                    form.setFieldsValue({ category: value });
                  }}
                />
              </Form.Item>
            )}
          </>
        )}

        <FormFooterButtons
          form={form}
          formName={name ? name : "editDetailsCircle"}
          handleCancel={closeModal}
          editInfo={editInfo}
          setDoSplit={setDoSplit}
          setEditInfo={setEditInfo}
        />
      </div>
    </Form>
  );
}

function FormFooterButtons({
  form,
  formName,
  handleCancel,
  editInfo,
  setDoSplit,
  setEditInfo,
}) {
  const handleSubmit = () => {
    setEditInfo(false);
    setDoSplit(false);
    console.log("submitting form");
    console.log(form.getFieldsValue());
    form.submit();
  };
  return !editInfo ? (
    <FooterContainer>
      <AddInfoButton
        className="footer"
        data-cy="Split_button"
        type="button"
        onClick={() => {
          setDoSplit(true);
          setEditInfo(true);
        }}
      >
        Split
      </AddInfoButton>
      <EditSaveButton
        type="button"
        onClick={() => {
          setEditInfo(true);
        }}
      >
        Edit
      </EditSaveButton>
    </FooterContainer>
  ) : (
    <FooterContainer>
      <AddInfoButton
        className="footer"
        type="button"
        onClick={() => {
          setDoSplit(false);
          setEditInfo(false);
        }}
      >
        Cancel
      </AddInfoButton>
      {/* we need to do this weird styling because the the button is inside of the Form.Item */}
      <div style={{ width: "45%", height: "100%" }}>
        <Form.Item name="submit" style={{ width: "100%", height: "100%" }}>
          <EditSaveButton
            className="FormItem"
            form={formName}
            data-cy="save_button"
            key="submit"
            htmlType="submit"
            onClick={handleSubmit}
          >
            Save
          </EditSaveButton>
        </Form.Item>
      </div>
    </FooterContainer>
  );
}

function SplitAmountFormItem({ maxSplit }) {
  return (
    <>
      {/* <label htmlFor="splitAmount">Split Amount</label> */}
      <AntSplitEntry
        total={maxSplit}
        name="splitAmount"
        rules={[
          {
            required: true,
            message: "The split amount is required.",
          },
          {
            message:
              "please enter a number between 0.01 - " +
              maxSplit +
              " dollars and cents",
            validator: (_, value) => {
              if (parseFloat(value) >= 0.01 && parseFloat(value) <= maxSplit) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "please enter a number between 0.01 - " +
                    maxSplit +
                    " dollars and cents",
                );
              }
            },
          },
        ]}
      ></AntSplitEntry>
    </>
  );
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 20px;
  // gap: 20px;
`;
const EditSaveButton = styled.button`
  border-radius: 40px;
  background: linear-gradient(
    180deg,
    #ffde33 -74.51%,
    #fffa8a 82.43%,
    #fffef9 152.94%
  );
  box-shadow: 0px 2px 8px 0px #ffc83abf;
  width: 45%;
  height: 100%;
  border: none;
  color: #0b0d16bf;
  font-size: 18px;
  font-weight: 700;
  &.FormItem {
    width: 100%;
    height: 50px; //make sure its the same as FooterContainer height
  }
}
`;

const AddInfoButton = styled.button`
  border: 1px solid #ffac18;
  border-radius: 100px;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #0b0d16bf;
  &.footer {
    margin: 0;
    // margin-left: 0px;
    box-shadow: 0px 2px 8px 0px #ffc83abf;
    font-size: 18px;
    color: #0b0d16bf;
    width: 45%;
    height: 100%;
  }
`;

const LabeledField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  p {
    margin: 0;
  }
  .label {
    color: #0b0d1680;
  }
  .value {
    color: #0b0d16;
  }
`;

const BigText = styled.div`
  font-size: 26px;
  font-weight: 500;
  p {
    margin: 0;
  }
  .number {
    font-size: 34px;
  }
`;

import CoolFormInput from "./CoolFormInput";

export default function CoolFormInputPhone(
  hasInitialValue = false,
  required = false,
) {
  return (
    <CoolFormInput
      name="phone_number"
      placeholder="Phone Number"
      hasInitialValue={hasInitialValue}
      rules={[
        { required: required, message: "Please enter your phone number." },
        {
          min: 5,
          message: "Phone number must be at least 5 characters.",
        },
        {
          max: 80,
          message: "Phone number must be at most 80 characters.",
        },
        {
          // Phone number can only include numbers and the symbols + - ( ) and spaces.
          pattern: /^[0-9+\-() ]+$/,
          message:
            "Phone number can only include numbers and the symbols + - ( ) and spaces.",
        },
      ]}
    />
  );
}

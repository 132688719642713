import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import backend from "../../functions/backend";

export default function Replies() {
  const [replyList, setReplyList] = useState([]);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const { postId } = useParams();
  const checkResponseFail = useCheckResponseFail();

  const getReplies = async () => {
    const response = await backend.get("/v1/community/reply?post_id=" + postId);
    if (checkResponseFail(response, "Failed to get replies:")) return;

    let replyData = [];

    if (response.items) {
      for (let i = 0; i < response.items.length; i++) {
        replyData[i] = {};
        replyData[i].id = response.items[i].id;
        replyData[i].user_id = response.items[i].user_id;
        replyData[i].post_id = response.items[i].post_id;
        replyData[i].text = response.items[i].text;
      }
    }
    setReplyList(replyData);
  };

  useEffect(() => {
    getReplies();
  }, []);

  const addReply = async () => {
    const data = {
      post_id: postId,
      text: text,
    };

    const response = await backend.post("/v1/community/reply", data);
    if (checkResponseFail(response, "Failed to add reply:")) return;

    getReplies();
  };

  const handleSubmitReply = (e) => {
    e.preventDefault();
    console.log({ text });
    addReply();
    setText("");
  };

  const handleBack = () => {
    navigate("/community");
  };

  return (
    <div>
      <MoneyFlowHeader />
      <button onClick={() => handleBack()}>Back to Community</button>
      <h2>Post {postId}</h2>
      <form onSubmit={handleSubmitReply}>
        <label htmlFor="reply">Write your reply</label>
        <br />
        <textarea
          rows={5}
          value={text}
          onChange={(e) => setText(e.target.value)}
          type="text"
          name="text"
          className="modalInput"
        />
        <br />
        <button>Add Reply</button>
      </form>
      <div>
        <h3>Replies</h3>
        {replyList.map((reply) => (
          <div key={reply.id}>
            <pre>{reply.text}</pre>
            <div>
              <span style={{ opacity: "0.6" }}>by user {reply.user_id}</span>{" "}
            </div>
          </div>
        ))}
      </div>
      <BottomNavBar />
    </div>
  );
}
